<template>
  <div
    class="Banner"
    :class="{
      'NoGradient': noGradient,
      'MenuBanner': forMenu
    }"
    :style="{
      'background-image': src ? `url(${src})` : '',
      'min-height': src ? `${minHeight}px` : ''
    }">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'banner',
  props: {
    src: {
      type: String,
      default: null
    },
    minHeight: {
      type: Number,
      default: 0
    },
    noGradient: {
      type: Boolean,
      default: true
    },
    forMenu: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.Banner {
  width: 100%;
  background-size: cover;
  background-position: center center;

  &:not(.MenuBanner) {
    position: relative;
  }
  &.MenuBanner {
    display: flex;
    flex-direction: column-reverse;

    @media only screen and #{$tablet-down} {
      padding-bottom: $space-m;
    }
  }
  &:not(.NoGradient):before {
    content: "";
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(transparent, transparent 40%, rgba(0,0,0,0.5) 100%);
  }
  /deep/ > * {
    position: relative;
    z-index: 1;
  }
}
</style>
